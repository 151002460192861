import React from 'react';
import _ from 'lodash';
import {ThemeProvider} from 'styled-components';
import sho from './sho';
import nrk from './nrk';
import nxs from './nxs';
import azn from './azn';
import fsh from './fsh';
import hum from './hum';
import amf from './amf';

const Theme = ({store, children, themeFromState}) => (
  <ThemeProvider theme={() => {

    let theme;
    if (!_.isNil(store)) {
      const state = store.getState();
      theme = state.reducer.theme || 'sho';
    } else {
      theme = themeFromState;
    }

    switch (theme) {
      case 'sho':
        return sho;
      case 'nrk':
        return nrk;
      case 'azn':
        return azn;
      case 'fsh':
        return fsh;
      case 'hum':
        return hum;
      case 'amf':
        return amf;
      case 'nxs':
      default:
        return nxs;
    }
  }}>{children}</ThemeProvider>
);

export default Theme;
