import React from 'react';
import {withRouter} from 'react-router-dom';
import LayoutContainer from '../../components/LayoutContainer';
import Title from '../../components/Title';
import Box from '../../components/Box';
import Button from '../../components/Button';
import MainContainer from '../../components/MainContainer';
import Logo from '../../components/Logo';
import DisplayIban from '../../components/DisplayIban';
import ProgressBar from '../../components/ProgressBar';
import Input from '../../components/Input';
import Label from '../../components/Label';
import {Field, Form, FormValidation, resetSubmitting} from '@nexios/frontend-forms';
import ErrorMessage from '../../components/ErrorMessage';
import FieldWrapper from '../../components/FieldWrapper';
import _ from 'lodash';
import {updateSession, cleanseAddress} from '../../reducer';
import {gaStep2} from '../../googleAnalytics/step2';
import {connect} from 'react-redux';
import Validation from '../../components/Validation';
import DateInput from '../../components/DateInput';
import Checkbox from '../../components/Checkbox';
import Backbutton from '../../components/Backbutton';
import RadioButtonContainer from '../../components/RadioButtonContainer';
import {Helmet} from 'react-helmet';
import Telemarketing from '../../components/Telemarketing';
import styled, {withTheme} from 'styled-components';
import useScrollToTop from '../../hooks/useScrollToTop';
import Teaser from '../../components/Teaser';
import ProductImage from '../../components/ProductImage';
import CollectingSideBar from '../../components/CollectingSideBar';
import formatPhone from '@nexios/phone-number-formatter';
import genders from '../../utils/genders';
import TextToHtml from '../../components/TextToHtml';
import ButtonContainer from '../../components/ButtonContainer';

const hasValue = val => !_.isNil(val) && _.trim(val) !== '';

const isAddressVisible = ctx => {
  if (ctx.donateAsOrganisation === true) {
    return ctx.organisation.isAddressVisible;
  }

  return ctx.person.isAddressVisible;
};

const setChannels = (formContext, ctx, data) => {
  const channels = [];
  if (formContext.allowPreferredChannelWhatsApp && (data.donateAsOrganisation === true ? formContext.organisation.isTelephoneVisible : formContext.person.isTelephoneVisible)) {
    channels.push({
      label: 'WhatsApp',
      value: {
        icon: 'fa-whatsapp',
        value: 'whatsapp',
        title: 'WhatsApp'
      }
    });
  }
  if (formContext.allowPreferredChannelEmail) {
    channels.push({
      label: 'E-mail',
      value: {
        icon: 'fa-envelope-o',
        value: 'email',
        title: 'E-mail'
      }
    });
  }
  if (formContext.allowPreferredChannelNone) {
    channels.push({
      label: 'Liever niet',
      value: {
        icon: 'fa-times-circle-o',
        value: 'none',
        title: 'Liever niet'
      }
    });
  }
  ctx.setList('channels', channels);
  const allowedChannels = channels.map(channel => channel.value.value);
  if(!_.isNil(data.preferredChannel) && allowedChannels.indexOf(data.preferredChannel) === -1) {
    ctx.setValues({preferredChannel: null});
  }
};

const Step2 = ({
                 formContext,
                 save,
                 history,
                 cleanse,
                 gaStep2,
                 resetSubmittingForm,
                 next,
                 previous,
                 progressBar,
                 isProductForm,
                 theme
               }) => {
  useScrollToTop();
  let cleansing = false;

  const Email = () => (
    <div className='marginBottom'>
      <Field
        label='E-mailadres'
        name='email'
        inputComponent={Input}
        component={FieldWrapper}
        placeholder='E-mailadres'
        inputType='email'
        autoComplete='email'
      />
      <Field
        name='allowNewsletter'
        label={formContext.newsletterText || 'Houd mij op de hoogte over nieuwe ontwikkelingen.'}
        component={FieldWrapper}
        inputComponent={Checkbox}
        hideLabel
        subtle
      />
    </div>
  );

  const PersonForm = (props) => (<>
    <Gender />
    <Name isFirstNameVisible={props.isFirstNameVisible} />
    <Address {...props} />
    <Phone {...props} />
    <DateOfBirth />
    <Email />
  </>);

  const formConfig = {
    onSubmit: () => {
      if (cleansing) {
        resetSubmittingForm();
      } else {
        gaStep2(formContext);
        save([
          'dateOfBirth',
          'donateAsOrganisation',
          'email',
          'firstName',
          'initials',
          'infix',
          'lastName',
          'gender',
          'postalCode',
          'houseNumber_i',
          'houseNumberAddition',
          'street',
          'city',
          'renderAsPerson',
          'renderAsOrganisation',
          'telephone',
          'organisationName',
          'allowTelemarketing',
          'allowNewsletter',
          'preferredChannel'
        ], () => {
          history.push(`${next}${history.location.search}`);
        });
      }
    },
    onInit: (ctx) => {
      ctx.setDefaultValues({
        donateAsOrganisation: false
      });

      ctx.setList('days', _.range(1, 31).map(value => ({label: `${value}`, value: `${value}`})));
      ctx.setList('genders', genders(formContext));

      setChannels(formContext, ctx, ctx);

      if (theme.allowNewsletterDefault === true) {
        ctx.setDefaultValues({
          allowNewsletter: true
        });
      }
      if (formContext.telemarketingOptIn !== 'none') {
        ctx.setDefaultValues({
          allowTelemarketing: true
        });
      }

    },
    onDataChanged: (ctx, callback) => {
      setChannels(formContext, ctx, ctx.data);

      const donateAsOrganisation = _.get(ctx, ['data', 'donateAsOrganisation']);
      const previousDonateAsOrganisation = _.get(ctx, ['previousData', 'donateAsOrganisation']);

      if (donateAsOrganisation !== previousDonateAsOrganisation) {
        ctx.validate(true);
        return callback();
      }

      const postalCode = _.get(ctx, ['data', 'postalCode']);
      const previousPostalCode = _.get(ctx, ['previousData', 'postalCode']);
      const houseNumber = _.get(ctx, ['data', 'houseNumber_i']);
      const previousHouseNumber = _.get(ctx, ['previousData', 'houseNumber_i']);
      const houseNumberAddition = _.get(ctx, ['data', 'houseNumberAddition']);
      const previousHouseNumberAddition = _.get(ctx, ['previousData', 'houseNumberAddition']);

      let isChanged = false;

      if (postalCode !== previousPostalCode || houseNumber !== previousHouseNumber || houseNumberAddition !== previousHouseNumberAddition) {
        isChanged = true;
      }

      if (hasValue(postalCode) &&
        hasValue(houseNumber) &&
        isChanged) {

        // if we are already cleansing do not start again, request may interfer
        if (cleansing) {
          return;
        }

        cleansing = true;

        cleanse({
          postalCode,
          houseNumber_i: houseNumber,
          houseNumberAddition
        }, (data) => {
          if (data.status === 500) {
            data = {
              result: {
                success: false
              },
              address: {
                street: '',
                city: ''
              }
            };
          }

          const addressCleansingStatus = _.get(data, ['result', 'success'], false) ? 'GREEN' : 'RED';

          const cleansedAddress = _.get(data, ['result', 'address']);

          if (addressCleansingStatus === 'GREEN') {
            ctx.setValues({
              street: cleansedAddress.street,
              city: cleansedAddress.city,
              postalCode: cleansedAddress.postalCode,
              houseNumber_i: cleansedAddress.houseNumber_i,
              houseNumberAddition: cleansedAddress.houseNumberAddition,
              addressCleansingStatus
            });
          } else {
            ctx.setValues({
              street: null,
              city: null,
              addressCleansingStatus
            });
          }

          ctx.validate(true);

          cleansing = false;

          return callback();
        });
      } else if (!hasValue(postalCode) || !hasValue(houseNumber)) {
        ctx.setValues({
          addressCleansingStatus: null,
          street: null,
          city: null
        });
      }

      const email = _.get(ctx, ['data', 'email']);
      const previousEmail = _.get(ctx, ['previousData', 'email']);

      if (hasValue(email) && email !== previousEmail) {
        ctx.setValues({
          email: _.trim(email)
        });
      }

      return callback();
    },
    fields: {
      telemarketingInfoText: {
        isVisible: ctx => {
          if (ctx.telemarketingOptIn !== 'text' && ctx.telemarketingOptIn !== 'button') {
            return false;
          }

          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isTelephoneVisible;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      allowNewsletter: {
        isVisible: () => {
          return formContext.allowNewsletter === true && formContext.allowNewsletterDisplay === 'step2' && formContext.donationPageType !== '12_donationAnon';
        }
      },
      allowTelemarketing: {
        isVisible: ctx => {
          if (formContext.telemarketingOptIn !== 'checkbox') {
            return false;
          }

          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isTelephoneVisible;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: false
      },
      renderAsOrganisation: {
        isVisible: ctx => ctx.donateAsOrganisation === true
      },
      renderAsPerson: {
        isVisible: ctx => ctx.donateAsOrganisation !== true
      },
      donateAsOrganisation: {
        isVisible: (ctx) => ctx.allowDonateAsOrganisation === true
      },
      organisationName: {
        isVisible: ctx => ctx.donateAsOrganisation === true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.organisationName) && _.trim(ctx.organisationName) !== ''
          }
        ]
      },
      email: {
        isVisible: true,
        isRequired: true,
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.email) && ctx.email !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.email) || ctx.email === '') {
                return true;
              }

              return /^[-0-9a-zA-Z.+_]{1,63}@([-0-9a-zA-Z+_]+\.){1,63}[a-zA-Z]{1,63}$/.test(_.trim(ctx.email));
            },
            message: 'Er is geen geldig e-mailadres ingevuld.'
          }
        ]
      },
      gender: {
        isVisible: ctx => ctx.donateAsOrganisation !== true && ctx.person.isGenderVisible === true,
        isRequired: ctx => ctx.donateAsOrganisation !== true && ctx.person.isGenderRequired === true,
        rules: [
          {
            isValid: ctx => {
              if (ctx.donateAsOrganisation === true) {
                return true;
              }

              if (ctx.person.isGenderRequired) {
                return !_.isNil(ctx.gender) && _.trim(ctx.gender) !== '';
              }

              return true;
            }
          }
        ]
      },
      initials: {
        isVisible: ctx => {
          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isInitialsVisible;
          }

          return ctx.person.isInitialsVisible;
        },
        isRequired: ctx => {
          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isInitialsRequired;
          }

          return ctx.person.isInitialsRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.donateAsOrganisation === true && ctx.organisation.isInitialsRequired) {
                return !_.isNil(ctx.initials) && _.trim(ctx.initials) !== '';
              }

              if (ctx.donateAsOrganisation !== true && ctx.person.isInitialsRequired) {
                return !_.isNil(ctx.initials) && _.trim(ctx.initials) !== '';
              }

              return true;
            }
          }
        ]
      },
      firstName: {
        isVisible: ctx => {
          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isFirstNameVisible;
          }

          return ctx.person.isFirstNameVisible;
        },
        isRequired: ctx => {
          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isFirstNameRequired;
          }

          return ctx.person.isFirstNameRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.donateAsOrganisation === true && ctx.organisation.isFirstNameRequired) {
                return !_.isNil(ctx.firstName) && _.trim(ctx.firstName) !== '';
              }

              if (ctx.donateAsOrganisation !== true && ctx.person.isFirstNameRequired) {
                return !_.isNil(ctx.firstName) && _.trim(ctx.firstName) !== '';
              }

              return true;
            }
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.firstName) || ctx.firstName === '') {
                return true;
              }

              return _.trim(ctx.firstName).length > 1;
            },
            message: 'Voornaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      infix: {},
      lastName: {
        rules: [
          {
            isValid: ctx => !_.isNil(ctx.lastName) && ctx.lastName !== ''
          },
          {
            isValid: ctx => {
              if (_.isNil(ctx.lastName) || ctx.lastName === '') {
                return true;
              }

              return _.trim(ctx.lastName).length > 1;
            },
            message: 'Achternaam moet minimaal 2 karakters hebben.'
          }
        ]
      },
      postalCode: {
        isVisible: isAddressVisible,
        isRequired: ctx => {
          if (hasValue(ctx.houseNumber_i) || hasValue(ctx.houseNumberAddition)) {
            return true;
          }

          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isAddressRequired;
          }

          return ctx.person.isAddressRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (!hasValue(ctx.postalCode)) {
                return true;
              }

              return /^[1-9][0-9]{3}\W?[a-zA-Z]{2}$/.test(_.trim(ctx.postalCode));
            },
            message: 'Er is geen geldige postcode ingevuld.'
          },
          {
            isValid: ctx => {
              if (hasValue(ctx.houseNumber_i) || hasValue(ctx.houseNumberAddition)) {
                return hasValue(ctx.postalCode);
              }

              if (ctx.donateAsOrganisation === true && ctx.organisation.isAddressRequired) {
                return hasValue(ctx.postalCode);
              }

              if (ctx.donateAsOrganisation !== true && ctx.person.isAddressRequired) {
                return hasValue(ctx.postalCode);
              }

              return true;
            }
          },
          {
            message: 'Het opgegeven adres kan niet worden gevonden.',
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          }
        ]
      },
      houseNumber_i: {
        isVisible: isAddressVisible,
        isRequired: ctx => {
          if (hasValue(ctx.postalCode) || hasValue(ctx.houseNumberAddition)) {
            return true;
          }

          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isAddressRequired;
          }

          return ctx.person.isAddressRequired;
        },
        rules: [
          {
            isValid: ctx => ctx.addressCleansingStatus !== 'RED'
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.houseNumber_i)) {
                return true;
              }

              return ctx.houseNumber_i.length < 8 && /^\d*$/.test(ctx.houseNumber_i);
            },
            message: 'Er is geen geldig huisnummer ingevuld. Controleer of er alleen cijfers gebruikt zijn.'
          },
          {
            isValid: ctx => {
              if (hasValue(ctx.postalCode) || hasValue(ctx.houseNumberAddition)) {
                return hasValue(ctx.houseNumber_i);
              }

              if (ctx.donateAsOrganisation === true && ctx.organisation.isAddressRequired) {
                return hasValue(ctx.houseNumber_i);
              }

              if (ctx.donateAsOrganisation !== true && ctx.person.isAddressRequired) {
                return hasValue(ctx.houseNumber_i);
              }

              return !(ctx.addressCleansingStatus === 'RED' && !_.isNil(ctx.houseNumber_i));
            }
          }
        ]
      },
      houseNumberAddition: {
        isVisible: isAddressVisible
      },
      telephone: {
        isVisible: ctx => {
          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isTelephoneVisible;
          }

          return ctx.person.isTelephoneVisible;
        },
        isRequired: ctx => {
          if (ctx.preferredChannel === 'whatsapp') {
            return true;
          }
          if (ctx.donateAsOrganisation === true) {
            return ctx.organisation.isTelephoneRequired;
          }

          return ctx.person.isTelephoneRequired;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.preferredChannel === 'whatsapp') {
                return hasValue(ctx.telephone);
              }

              if (ctx.donateAsOrganisation === true && ctx.organisation.isTelephoneRequired) {
                return hasValue(ctx.telephone);
              }

              if (ctx.donateAsOrganisation !== true && ctx.person.isTelephoneRequired) {
                return hasValue(ctx.telephone);
              }

              return true;
            }
          },
          {
            isValid: ctx => {
              if (!hasValue(ctx.telephone)) {
                return true;
              }

              return formatPhone(ctx.telephone).result === 'GREEN';
            },
            message: 'Er is geen geldig telefoonnummer ingevuld.'
          }
        ]
      },
      dateOfBirth: {
        isVisible: ctx => ctx.donateAsOrganisation !== true && ctx.person.isDateOfBirthVisible,
        isRequired: ctx => {
          if (ctx.donateAsOrganisation !== true) {
            return ctx.person.isDateOfBirthRequired;
          }

          return false;
        },
        rules: [
          {
            isValid: ctx => {
              if (ctx.donateAsOrganisation === true) {
                return true;
              }

              if (ctx.person.isDateOfBirthRequired) {
                return hasValue(ctx.dateOfBirth);
              }

              return true;
            }
          }
        ]
      },
      street: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      },
      city: {
        isVisible: ctx => ctx.addressCleansingStatus === 'GREEN' && isAddressVisible(ctx)
      }
    }
  };

  const OrganisationForm = (props) => (
    <>
      <OrganisationName />
      <Address {...props} />
      <Name isFirstNameVisible={props.isFirstNameVisible} />
      <Email />
      <Phone {...props} />
    </>
  );

  return <>
    <Helmet>
      <title>{formContext.contactDataBrowserTitle}</title>
    </Helmet>
    <MainContainer
      backgroundImageUrl={formContext.backgroundImageUrl}
      backgroundImageTabletUrl={formContext.backgroundImageTabletUrl}
      backgroundImageMobileUrl={formContext.backgroundImageMobileUrl}
    />
    <LayoutContainer
      position={formContext.textPosition}
      header={<div>
        <div style={{float: 'left', display: 'inline-block'}}>
          <Logo url={formContext.websiteUrl} />
        </div>
        <DisplayIban iban='NL08 INGB 0000 0005 55' />
      </div>}
      content={<Box>
        <Title title={theme.step2WidgetHeaderText || formContext.step2Header}><ProgressBar progress={progressBar} /></Title>
        <Form
          autoComplete='on'
          formInstanceKey='donate'
          formContext={formContext}
          formConfig={formConfig}>
          <FormValidation
            component={ErrorMessage}
            errorMessage='Je hebt nog niet alle velden ingevuld.' />
          <div className='widgetContent'>
            <Field
              name='donateAsOrganisation'
              label='Doneer als organisatie'
              component={FieldWrapper}
              inputComponent={Checkbox}
              hideLabel
            />
            <Field
              name='renderAsPerson'
              telemarketingOptIn={formContext.telemarketingOptIn}
              telemarketingInfoText={formContext.telemarketingInfoText}
              telemarketingCheckBoxText={formContext.telemarketingCheckBoxText}
              isAddressVisible={formContext.person.isAddressVisible}
              isAddressOptional={!formContext.person.isAddressRequired}
              isFirstNameVisible={formContext.person.isFirstNameVisible}
              component={PersonForm}
            />
            <Field
              name='renderAsOrganisation'
              telemarketingOptIn={formContext.telemarketingOptIn}
              telemarketingInfoText={formContext.telemarketingInfoText}
              telemarketingCheckBoxText={formContext.telemarketingCheckBoxText}
              isAddressVisible={formContext.organisation.isAddressVisible}
              isAddressOptional={!formContext.organisation.isAddressRequired}
              isFirstNameVisible={formContext.organisation.isFirstNameVisible}
              component={OrganisationForm}
            />
            {formContext.allowPreferredChannel === true && <div className='marginBottom'>
              <TextToHtml text={formContext.preferredChannelTextHtml_txt} />
              <Field
                list='channels'
                name='preferredChannel'
                component={(props) => {
                  const list = props.list.map(a => {
                    a.label = null;
                    a.icon = a.value.icon;
                    a.title = a.value.title;
                    a.value = a.value.value;
                    return a;
                  });
                  return <div className='marginBottom amountButtons'>
                    <ButtonContainer
                      fieldName='preferredChannel'
                      valueName='preferredChannel'
                      list={list} /></div>;
                }}
              />
            </div>}
            <div className='marginBottom'>
              <FormValidation
                type='submit'
                label={!_.isNil(formContext.contactDataCallToActionLabel) ? formContext.contactDataCallToActionLabel : isProductForm ? 'Volgende stap' : 'Doneer nu'}
                component={Button}
              />
            </div>
            {formContext.telemarketingOptIn === 'button' && <Telemarketing
              telemarketingOptIn={formContext.telemarketingOptIn}
              telemarketingInfoText={formContext.telemarketingInfoText}
              telemarketingCheckBoxText={formContext.telemarketingCheckBoxText}
            />}
            <div>
              <Backbutton link={`${previous}${history.location.search}`} />
            </div>
          </div>
        </Form>
      </Box>}
      sidebar={
        <>
          {isProductForm && <ProductImage url={_.get(formContext, ['productImageUrl'])} collapseIfEmpty />}
          {formContext.isCollecting ? <CollectingSideBar><Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} /></CollectingSideBar> :
            <Teaser header={formContext.pageTeaserHeader} body={formContext.pageTeaserBodyText} />}
        </>
      }
      footer={formContext.footer}
    />
  </>;
};

const mapStateToProps = (state) => {
  return {
    formContext: state.reducer.formContext,
    isProductForm: state.reducer.isProductForm
  };
};

const mapDispatchToProps = (dispatch) => ({
  save: (fields, callback) => {
    dispatch(updateSession(fields, {
      isStep2Completed: true
    }, callback));
  },
  gaStep2: (formContext) => {
    dispatch(gaStep2(formContext));
  },
  cleanse: (data, callback) => {
    dispatch(cleanseAddress(data, callback));
  },
  resetSubmittingForm: () => dispatch(resetSubmitting('donate'))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(Step2)));

const AddressRow = styled.div`
  display: flex;

  > * {
    margin: 0 0 0 5px;

    flex: 1;

    &:first-child {
      margin: 0;
      flex: 3;
    }
  }
`;

function Address(props) {
  if (!props.isAddressVisible) return <></>;
  return (
    <div className='marginBottom'>
      <Label label='Adres' isOptional={props.isAddressOptional} />
      <AddressRow>
        <Field
          name='postalCode'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder={'Postcode'}
          inline
          hideValidation
          autoComplete='postal-code'
        />
        <Field
          name='houseNumber_i'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder={'Huisn'}
          inline
          hideValidation
          inputType='tel'
          autoComplete='05d2ae54-5b99-4418-9d38-fde0ab6d553f'
        />
        <Field
          name='houseNumberAddition'
          component={FieldWrapper}
          inputComponent={Input}
          placeholder={'Toev'}
          hideValidation
          autoComplete='f1a99033-6af3-4aa2-94db-1de7ff1af61d'
        />
      </AddressRow>
      <Field
        name='postalCode'
        component={Validation} />
      <Field
        name='houseNumber_i'
        component={Validation} />
      <Field
        name='houseNumberAddition'
        component={Validation} />
      <Field
        component={({value}) => <div className='addressBox'>{value}&nbsp;</div>}
        name='street' />
      <Field
        component={({value}) => <div className='addressBox'>{value}</div>}
        name='city' />
    </div>
  );
}

const Phone = (props) => {
  return <>
    <div className='marginBottom'>
      <Field
        label='Telefoonnummer'
        name='telephone'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder='Telefoonnummer'
        inputType='tel'
        autoComplete='tel'
      />
      {(props.telemarketingOptIn === 'text' || props.telemarketingOptIn === 'checkbox') && <Telemarketing {...props} />}
    </div>

  </>;
};

const DateOfBirth = () => (
  <div className='marginBottom'>
    <Field
      label='Geboortedatum'
      name='dateOfBirth'
      component={FieldWrapper}
      inputComponent={DateInput}
      autoComplete='e41c45bd-94c9-45d5-aaaf-0fd215a44d18'
    />
  </div>
);

const Gender = () => (
  <div className='marginBottom'>
    <Field
      label='Geslacht'
      name='gender'
      list='genders'
      component={FieldWrapper}
      inputComponent={(props) => {
        return <RadioButtonContainer fieldName='gender' valueName='gender' list={props.list} {...props} />;
      }}
    />
  </div>
);

const NameRow = styled.div`
  display: flex;

  > * {
    margin: 0 0 0 5px;

    flex: 1;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      flex: 3;
    }
  }
`;
const Name = (props) => (
  <div className='marginBottom'>
    <Label label='Naam' />
    {props.isFirstNameVisible && <div className='marginBottomTen'>
      <Field
        name='firstName'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Voornaam'}
        hideValidation
        autoComplete='given-name'
      />
    </div>}
    <NameRow>
      <Field
        name='initials'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Voorletters'}
        hideValidation
        inline
        autoComplete='49989326-3fcd-4f40-9f3c-67ad6f1b1fa9'
      />
      <Field
        name='infix'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Tussenv'}
        hideValidation
        autoComplete='90ab3315-3929-4643-b78d-9de3e727eadf'
      />
      <Field
        name='lastName'
        component={FieldWrapper}
        inputComponent={Input}
        placeholder={'Achternaam'}
        hideValidation
        autoComplete='family-name'
      />
    </NameRow>
    <Field
      name='firstName'
      component={Validation} />
    <Field
      name='initials'
      component={Validation} />
    <Field
      name='infix'
      component={Validation} />
    <Field
      name='lastName'
      component={Validation} />
  </div>
);

const OrganisationName = () => (
  <div className='marginBottom'>
    <Field
      label='Naam organisatie'
      name='organisationName'
      inputComponent={Input}
      component={FieldWrapper}
      placeholder='Naam organisatie'
      autoComplete='3031d027-4735-4604-9fa5-337235fe7798'
    />
  </div>
);
