import {getFormData, getProducts} from '../helpers';
import _ from 'lodash';

export function gaLead1 (formContext) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getFormData(state);
    const previousPageVariantName = _.get(data, ['previousPageVariantName']);
    const isRedirect = !_.isNil(previousPageVariantName);
    const gaEvent = {
      'event': 'formSubmit',
      'formName': _.get(formContext, ['pageVariantName']),
      'newsletterSubscription': !!_.get(data, ['allowNewsletter'])
    };

    if (isRedirect) {
      gaEvent.leadFormRedirect = previousPageVariantName;
    }

    window.dataLayer.push(gaEvent);

    window.dataLayer.push({
      'event': 'transactionSend',
      'ecommerce': {
        'purchase': {
          'actionField': {
            id: data.referenceKey,
            affiliation: 'Online Store',
            action: 'order'
          },
          products: getProducts(formContext, state)
        }
      }
    });
  };
}
